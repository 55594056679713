export const prompts = [
  `Write more writing prompts`,
  `When your eyes adjust to the darkness, you see another figure.`,
  `Everything changed when she left.`,
  `She withdrew the bayonet from his still-warm flesh.`,
  `Finally, she would see the city.`,
  `She had always wanted to see the city.`,
  `She never thought she would meet him.`,
  `The foreman returned, with protection.`,
  `It was enough that she had said his name.`,
  `They would see much more of each other in the following days.`,
  `He tried to catch her eye.`,
  `"It's not like the movies."`,
  `"You get accustomed to it".`,
  `"Well, I suppose that is one way to do it."`,
  `She watched him dance.`,
  `It wouldn't take much, just a sip of piña colada.`,
  `"I want to show you something. It's not far from here."`,
  `It wasn't the sight of blood so much as the smell of it that made her queasy.`,
  `"Everyone is going to be going."`,
  `The station attendant looked her up and down.`,
  `Seeing it, it didn't frighten her so much as fascinate her.`,
  `"Do you have a name?"`,
  `"Well, suit up; we have a long day ahead of us."`,
  `"Please, leave us alone."`,
  `The door began to open.`,
  `"She would have been about your age."`,
  `"What did you think would happen?"`,
  `"You left him alone?, with her?"`,
  `"It's worth a shot."`,
  `She sensed more than felt a cool breeze from underground.`,
  `"We're going to have to get under it, somehow."`,
  `He could hear the faintest clink of a piano.`,
  `You're not certain, but you think it might be breathing.`,
  `The silhouette looked almost like a woman.`,
  `And with that, he emptied the whole clip.`,
  `"It is with gratitude, Lord, that we--"  "Shut up."`,
  `He set the cup on the saucer and smiled.`,
  `She smiled at him.`,
  `It had started as a perfect day.`,
  `"What do they look like on the inside?"`,
  `"Please.  I'm trying to help you."`,
  `It was as quiet as the city ever got.`,
  `From above, it looked almost peaceful.`,
  `She paused to look at the stars.`,
  `"If you think about it, it was actually a beautiful thing."`,
  `He never felt fully at home with his mother.`,
  `She stepped into the boat.`,
  `"You're Jonathan?"  "No, I'm his brother."`,
  `It seemed there was only one way that this could end.`,
  `"Yes, the path leads down, but it also leads up."`,
  `"I don't want your help."`,
  `"What could you possibly do for me?"`,
  `It was more food than she had seen in weeks.`,
  `"I thought you would be taller."`,
  `It was a fine ship.`,
  `"But it's more fun because we're wearing costumes."`,
  `"I never told you how she introduced herself."`,
  `"It feels like flying."`,
  `"It tastes almost spicy."`,
]